<template>
  <v-container fluid>
    <div class="d-flex justify-center">
      <v-row no-gutters>
        <formularioParaCreacionDeUsuario :prop_tipo="'Agente de comercio'"></formularioParaCreacionDeUsuario>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col>
          <v-row>
            <v-col cols="3">
              <v-text-field clearable type="search" v-model="searchUser" label="Alias, nombre, sector"
                append-icon="mdi-account" autocomplete="false"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field clearable type="search" v-model="searchSector" label="Sector" append-icon="mdi-magnify"
                autocomplete="false"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field clearable type="search" v-model="searchRegion" label="Region" append-icon="mdi-magnify"
                autocomplete="false"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field clearable type="search" v-model="searchDivision" label="División" append-icon="mdi-magnify"
                autocomplete="false"></v-text-field>
            </v-col>

          </v-row>
        </v-col>
        <v-col cols="1">
          <v-btn small @click="main()" color="#031D6A" class="ml-2 white--text mt-3">
            Buscar
            <v-icon right dark> mdi-magnify </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- PARA MOSTRAR O NO LOS REGISTROS ELIMINADOS -->
    <v-row>
      <v-col cols="4">
        <v-switch class="ml-4 mt-n4" v-model="usuarios_status_mostrar_eliminados" :label="``">
          <template v-slot:label>
            <span style="font-size: 14px">Mostrar eliminados:
              {{ !usuarios_status_mostrar_eliminados ? 'No' : 'Si' }}</span>
          </template>
        </v-switch>
      </v-col>
    </v-row>

    <v-data-table disable-pagination :options.sync="options" :hide-default-footer="true" :headers="headers"
      :items="datosTabla" item-key="name" class="elevation-4 mt-10 mx-2">
      <template v-slot:[`item.gps`]="{ item }">
        <span v-if="item.dispositivo_marca || item.dispositivo_modelo"> {{ item.dispositivo_marca }} {{
          item.dispositivo_modelo }}</span>
        <div class="text-caption" v-else title="Sin dispositivo vinculado">--</div>
      </template>


      <!-- para chip de color que indica si está activo o no -->
      <template v-slot:[`item.usuario`]="{ item }">
        {{ item.usuario
        }}<v-chip class="ml-2" x-small v-if="item.activo === true" color="green lighten-4">Activa</v-chip>
      </template>
      <!-- botones/íconos para las configuraciones -->
      <template v-slot:[`item.actions`]="{ item }">
        <formularioParaEdicionDeUsuario :prop_existing_user="item" :prop_tipo="'Agente de comercio'">
        </formularioParaEdicionDeUsuario>
        <!-- 
        <v-icon
          title="asignación de módulos"
          class="align-center"
          size="19"
          color="black"
          @click="fnAccesoModulos(item)"
        >
          mdi-format-list-bulleted
        </v-icon> -->
        <v-btn x-small text>
          <v-icon class="align-center mx-2" title="log" size="19" color="black" @click="fnVinculacionDispositivo(item)">
            mdi-cellphone-cog
          </v-icon>
        </v-btn>
        <v-btn x-small text>
          <v-icon class="align-center ml-n1 mt-1" title="Historial de cambios de contraseña" size="22" color="black"
            @click="fnVerRegistros(item)">
            mdi-math-log
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <!-- PAGINACION -->
    <v-col cols="12">
      <v-col offset="9" cols="3">
        <v-select v-model="pageSize" :items="pageSizes" label="Registros por página"
          @change="handlePageSizeChange"></v-select>
      </v-col>
      <v-col class="">
        Total de registros: {{ totalRegistros }}
        <v-pagination class="float-right" v-model="page" :length="totalPages" total-visible="15"
          next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="handlePageChange"></v-pagination>
      </v-col>
    </v-col>
    <!-- FIN PAGINACION -->


    <!-- Ventanas que se muestran dependiendo del ícono cliqueado de las opciones de CONFIGURACIONES -->

    <!-- para confirmar usuario y contraseña -->
    <v-dialog v-model="dialogValidarUsuario" width="540">
      <v-card>
        <v-toolbar color="#031D6A" dark>
          <v-toolbar-title class="text-body-1 ml-4 font-weight-bold">Confirma tu usuario para poder editar la
            contraseña</v-toolbar-title>
        </v-toolbar>

        <div class="mx-7">
          <v-text-field ref="usuario" v-model="usuario" label="Usuario" prepend-icon="mdi-account" class="mt-4 mr-4"
            :rules="[rules.required]"></v-text-field>

          <v-text-field class="mr-4 mt-4" ref="password" v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required]" :type="show ? 'text' : 'password'"
            label="Contraseña" prepend-icon="mdi-lock" persistent-hint hint=""
            @click:append="show = !show"></v-text-field>
        </div>

        <v-card-actions class="justify-end pb-6">
          <v-btn text class="mr-4" color="blue-grey darken-1" @click="
            (dialogValidarUsuario = false),
            $refs.usuario.reset(),
            $refs.password.reset()
            ">
            CANCELAR
          </v-btn>
          <v-btn small color="#031D6A" class="px-3 white--text mr-4 ml-4" @click="fnValidarContrasenia()">
            VALIDA TU CONTRASEÑA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- para editar la contraseña del usuario -->
    <v-dialog v-model="dialogModificarUsuario" width="540">
      <v-card>
        <v-toolbar color="#031D6A" dark>
          <v-toolbar-title class="text-body-1 ml-4 font-weight-bold">Modificar la contraseña del usuario
            {{ infoUsuario.nombre }}</v-toolbar-title>
        </v-toolbar>

        <div class="mx-7">
          <v-text-field ref="modificarUsuario" v-model="modificarUsuario" label="Usuario" prepend-icon="mdi-account"
            class="mt-4 mr-4" :rules="[rules.required]"></v-text-field>

          <v-text-field class="mr-4 mt-4" ref="newPassword" v-model="newPassword"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required]" :type="show ? 'text' : 'password'"
            label="Nueva contraseña" prepend-icon="mdi-lock" persistent-hint hint=""
            @click:append="show = !show"></v-text-field>
        </div>

        <v-card-actions class="justify-end pb-6">
          <v-btn text class="mr-4" color="blue-grey darken-1" @click="
            (dialogModificarUsuario = false),
            $refs.modificarUsuario.reset(),
            $refs.newPassword.reset()
            ">
            CANCELAR
          </v-btn>
          <v-btn small color="#031D6A" class="px-3 white--text mr-4 ml-4" @click="fnConfirmarEditar()">
            EDITAR CONTRASEÑA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Para la asignación de módulos -->
    <v-dialog v-model="dialogAsignacionModulos" max-width="1300">
      <v-card>
        <v-card-title class="text-subtitle-1">({{ infoUsuario.usuario }}) {{ infoUsuario.nombre }}</v-card-title>

        <v-container fluid>
          <v-row>
            <v-col cols="6" md="6" class="px-8">
              <v-card min-height="450">
                <v-card-title>Módulos</v-card-title>
                <div class="mx-5">
                  <v-row>
                    <v-col cols="auto" class="mr-auto">
                      <p>Precalificaciones incompletas</p>
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox v-model="checkboxValue.precalificaciones" class="mt-n1"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n10">
                    <v-col cols="auto" class="mr-auto">
                      <p>Inicio de proceso</p>
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox v-model="checkboxValue.inicioProceso" class="mt-n1"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n10">
                    <v-col cols="auto" class="mr-auto">
                      <p>Revisión de gestión</p>
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox v-model="checkboxValue.revisionGestion" class="mt-n1"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n10">
                    <v-col cols="auto" class="mr-auto">
                      <p>Rectificaciones</p>
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox v-model="checkboxValue.rectificaciones" class="mt-n1"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n10">
                    <v-col cols="auto" class="mr-auto">
                      <p>Compromisos de pago</p>
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox v-model="checkboxValue.compromisoPago" class="mt-n1"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n10">
                    <v-col cols="auto" class="mr-auto">
                      <p>Aprobación de créditos</p>
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox v-model="checkboxValue.aprobacionCredito" class="mt-n1"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n10">
                    <v-col cols="auto" class="mr-auto">
                      <p>Seguimiento de gestión</p>
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox v-model="checkboxValue.seguimientoGestion" class="mt-n1"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n10">
                    <v-col cols="auto" class="mr-auto">
                      <p>Documentos pendientes por compromiso de pago</p>
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox v-model="checkboxValue.documentoPendientePago" class="mt-n1"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n10">
                    <v-col cols="auto" class="mr-auto">
                      <p>Gestiones concluidas</p>
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox v-model="checkboxValue.gestionesConcluidas" class="mt-n1"></v-checkbox>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>

            <v-col cols="6" md="6" class="px-8">
              <v-card min-height="450">
                <v-card-title class="ml-2">Asignación de módulos para agentes de comercio</v-card-title>
                <div class="ml-5">
                  <p class="text-body-2">Usuario: {{ infoUsuario.usuario }}</p>
                  <p class="text-body-2 mt-n2">
                    Agente de comercio: {{ infoUsuario.agente }}
                  </p>
                  <p class="text-body-2 mt-n2">
                    División: {{ infoUsuario.division }}
                  </p>
                  <p class="text-body-2 mt-n2">
                    Región: {{ infoUsuario.region }}
                  </p>
                  <p class="text-body-2 mt-n2">
                    Sector: {{ infoUsuario.sector }}
                  </p>
                  <p class="text-body-2 mt-n2">
                    Nombre: {{ infoUsuario.nombre }}
                  </p>

                  <v-divider class="mr-5"></v-divider>
                  <p class="text-body-2 mt-2">
                    La asignación de módulos permitirá al usuario
                    <strong>{{ infoUsuario.usuario }}</strong> ingresar al panel
                    y acceder a los siguiente módulos:
                  </p>
                  <p class="text-body-2 mt-n2">Módulos</p>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="justify-end pb-4">
          <v-btn text @click="dialogAsignacionModulos = false">
            CERRAR EDITOR USUARIO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ventana para desvincular dispositivo -->
    <v-dialog v-model="dialogViculacionDispositivo" max-width="800" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2">Vinculación de dispositivo para usuario ({{
          infoUsuario.usuario
        }})</v-card-title>
        <v-divider></v-divider>
        <v-container>
          <v-row>
            <v-col :cols="infoUsuario.dispositivo_id ? '6' : '12'">
              <div class="ml-3" v-if="infoUsuario.dispositivo_id">
                <p class="text-h6 font-weight-regular">Dispositivo actual</p>
                <p class="text-body-2 mt-n2 text--secondary">
                  Marca: {{ infoUsuario.dispositivo_marca }}
                </p>
                <p class="text-body-2 mt-n2 text--secondary">
                  Modelo: {{ infoUsuario.dispositivo_modelo }}
                </p>
                <p class="text-body-2 mt-n2 text--secondary">
                  Dispositivo: [{{ infoUsuario.dispositivo_id }}]
                </p>
                <p class="text-body-2 mt-n2 text--secondary">
                  Fecha vinculación: {{ fnGetDate(infoUsuario.fecha_vinculacion) }}
                </p>
              </div>
              <v-card flat>
                <v-card-text>
                  No hay dispositivo vinculado a este usuario.
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="6" v-if="infoUsuario.dispositivo_id">
              <div class="text-center mt-10">
                <v-checkbox small v-model="desvinculacionCheck"
                  label="¿Desea desvincular este dispositivo?"></v-checkbox>
                <v-text-field :disabled="desvinculacionCheck == false ? true : false" v-model="desvinculacionMotivo"
                  label="Motivo de desvinculación:" color="primary"></v-text-field>
                <v-btn :disabled="desvinculacionMotivo == '' || null ? true : false" small color="red"
                  :dark="desvinculacionMotivo == '' || null ? false : true" @click="fnDesvincularDispositivo()">
                  DESVINCULAR DISPOSITIVO
                </v-btn>

                <p class="text-body-2 px-4 mt-4 text--secondary">
                  Desvincular el dispositivo premite al usuario iniciar sesión
                  en otro dispositivo
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="justify-end pb-4 pr-7">
          <v-btn text @click="dialogViculacionDispositivo = false" class="text--secondary">CANCELAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ventana para el historico de cambio de contraseñas -->
    <v-dialog v-model="dialogHistorico" max-width="95%" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2">LOG - histórico cambio de contraseñas</v-card-title>
        <v-divider></v-divider>
        <v-container>

        

          <tablaCambiosHistorico :prop_ver_log_de_usuario_id="ver_log_de_usuario_id" :prop_dialogHistorico="dialogHistorico"></tablaCambiosHistorico>
        </v-container>

        <v-card-actions class="justify-end pb-4 pr-7">
          <v-btn text class="text--secondary" @click="dialogHistorico = false">CANCELAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
import formularioParaCreacionDeUsuario from './crearAgenteDeComercio.vue';
import formularioParaEdicionDeUsuario from './editarAgenteDeComercio.vue';
import tablaCambiosHistorico from './tablaCmabioshistorico.vue'
export default {
  name: 'agentesDeComercio',
  components: {
    formularioParaCreacionDeUsuario,
    formularioParaEdicionDeUsuario,
    tablaCambiosHistorico
  },
  data() {
    return {
      ver_log_de_usuario_id:null,
      /**motivo por desvinculacion de dispositivo */
      desvinculacionCheck: false,
      desvinculacionMotivo: '',
      //
      usuarios_status_mostrar_eliminados: false, //para mostrar o no los registros eliminados
      //pagination
      options: {},
      page: 1,
      totalPages: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],
      totalRegistros: 0,
      //pagination fin
      //variables como parámtros del buscador
      searchUser: null,
      searchSector: null,
      searchRegion: null,
      searchDivision: null,

      //variables para mostrar o no las ventanas de dialogo
      dialogValidarUsuario: false,
      dialogModificarUsuario: false,
      dialogAsignacionModulos: false,
      dialogViculacionDispositivo: false,
      dialogHistorico: false,

      usuario: null, // para almacenar el usuario ingresado en la ventana de confirmar usuario
      password: null, //para la contraseña en la ventana de confirmar (editar)

      modificarUsuario: null, //para la modificacion de las credenciales
      newPassword: null, // almacenar la nueva contraseña

      //--------para los checkbox
      checkboxValue: {
        precalificaciones: false,
        inicioProceso: false,
        revisionGestion: false,
        rectificaciones: false,
        compromisoPago: false,
        aprobacionCredito: false,
        seguimientoGestion: false,
        documentoPendientePago: false,
        gestionesConcluidas: false,
      },

      //para mostrar o no las contraseñas
      show: false,
      rules: {
        required: (v) => !!v || 'Este campo es obligatorio',
      },

      //encabezados para la tabla
      headers: [
        {
          text: 'Nombre de usuario',
          align: 'start',
          value: 'nombre',
          sortable: false,
        },
        { text: 'Usuario', align: 'start', value: 'usuario', sortable: false },
        { text: 'Teléfono', align: 'start', value: 'telefono', sortable: false },
        { text: 'Sector', align: 'start', value: 'sector', sortable: false },
        {
          text: 'División',
          align: 'start',
          value: 'division',
          sortable: false,
        },
        { text: 'Región', align: 'start', value: 'region', sortable: false },

        { text: 'Nombre', align: 'start', value: 'nombre', sortable: false },
        { text: 'País', align: 'start', value: 'pais', sortable: false },
        {
          text: 'Dispositivo vinculado',
          align: 'start',
          value: 'gps',
          sortable: false,
        },

        {
          text: 'Configuraciones',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],

      //datos para la tabla (se tiene un dato de prueba)
      datosTabla: [
        {
          usuario: 'sector03',
          activo: true,
          sector: '03',
          division: 'División 3',
          region: 'Centro norte',
          agente: 'Supervisora',
          nombre: 'Agente de comercio 03',
          pais: 'Guatemala',
          gps: 'Encendido',
          bateria: '6%',
          marcaCelular: 'samsumng',
          modeloCelular: 'SM-A226BR',
          dispositivo:
            '-4, -40, 41, 51, 33, 124, 36, -32, -119, -93, 110, -42, 90, -117, 69, -76, 62, -75, 37, -43, -114, -29, 21, 84, -79, 56, -87, -56, -87, -56, -66, -20, 125,98',
          fecha_vinculacion: '01 octubre 2023 11:30 am',
        },
      ],

      infoUsuario: {}, //para mostrar la info en las ventanas de dialogo

      //valores de prueba para la tabla con le historial de cambio de contraseña
      datosHistorial: [
        { user: 'jgarcía', fechaActualizacion: '01 octubre 2023 01:35 pm' },
        { user: 'jgarcía', fechaActualizacion: '05 octubre 2023 04:35 pm' },
        { user: 'jgarcía', fechaActualizacion: '08 octubre 2023 08:35 pm' },
        { user: 'jgarcía', fechaActualizacion: '12 octubre 2023 18:35 pm' },
      ],
    };
  },
  watch: {
    options: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue) {
        this.main();
      },
      deep: true,
      immediate: true,
    },
    search_input() {
      this.page = 1;
      this.main();
    },
    usuarios_status_mostrar_eliminados() {
      this.main();
    },
  },
  mounted() {
    this.main();

    const { Usuarios} = this.$FeathersVuex.api;
    Usuarios.on('created', () => {
      this.main();
    });
  },
  methods: {
    ...mapActions('Usuarios', {
      createUsuarios: 'create',
      findUsuarios: 'find',
      getUsuarios: 'get',
      patchUsuarios: 'patch',
    }),
    /**paginacion */
    handlePageChange(value) {
      this.page = value;
      this.main();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.main();
    },
    /**paginacion fin */
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },
    getParams() {
      const params = {};
      /**paginacion */
      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;




      if (this.searchUser) {
        params['$or'] = [
          {
            nombre: {
              $like: '%' + this.searchUser + '%',
            },
          },
          {
            usuario: {
              $like: '%' + this.searchUser + '%',
            },
          }
        ];
      }

      if (this.searchSector) {
        params['sector'] = {
          $like: '%' + this.searchSector + '%',
        }
      }

      if (this.searchRegion) {
        params['region'] = {
          $like: '%' + this.searchRegion + '%',
        }
      }

      if (this.searchDivision) {
        params['division'] = {
          $like: '%' + this.searchDivision + '%',
        }
      }


      params['rol_nombre'] = 'Agente de comercio';
      if (
        this.options.sortBy &&
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        let sorting = {};
        let flag = this.options.sortDesc[0] ? -1 : 1;
        sorting[this.options.sortBy[0].toString()] = flag;
        params['$sort'] = sorting;
      } else {
        params['$sort'] = { id: -1 };
      }
      /**paginacion fin */

      //params['STATUS'] = 1;
      if (this.usuarios_status_mostrar_eliminados === false) {
        params['STATUS'] = 1;
      } else {
        params['STATUS'] = { $in: [1, 2] };
      }
      return params;
    },
    main() {
      this.findUsuarios({ query: this.getParams() }).then((r) => {
        this.datosTabla = r.data;
        this.totalRegistros = r.total;
        this.totalPages = Math.ceil(
          parseInt(r.total) / parseInt(this.pageSize)
        );
      });
    },
    //---------------función para editar contraseña
    fnEditarContrasenia(item) {
      console.log('Editar contraseña', item);
      this.infoUsuario = Object.assign(item);
      this.usuario = 'dtk'; //asignamos el valor del usuario de la fila a la que se le dio clic

      this.dialogValidarUsuario = true;
    },
    //funcion que se llama cuando se da clic en el botón para validar la contrasenia
    fnValidarContrasenia() {
      console.log('validando contraseña');
      console.log(this.usuario, this.password);
      this.dialogValidarUsuario = false; //cerramos la ventana de dialogo luego de haber verificado si el usuario y contraseña coinciden
      this.$refs.usuario.reset(); //reiniciamos el text-fiel de usuario
      this.$refs.password.reset(); //reiniciamos el text-fiel de constraseña

      //TODO: realizar la validacion de usuario y contraseña
      //si se validó abrimos la otra ventana para modificar la contraseña
      this.modificarUsuario = this.infoUsuario.nombre; //asignamos para mostrar en la ventana de dialogo
      this.dialogModificarUsuario = true;
    },

    fnConfirmarEditar() {
      console.log('se editó la contraseña');
      console.log(this.modificarUsuario, this.newPassword);
      this.dialogModificarUsuario = false;
      this.$refs.modificarUsuario.reset(); //borramos la ingresado por si acaso ingresaron algo
      this.$refs.newPassword.reset(); //borramos la ingresado por si acaso ingresaron algo
    },

    //--------------------------fin editar contraseña

    //función para editar el acceso a modulos del panel
    fnAccesoModulos(item) {
      console.log('editar acceso a módulos', item);
      this.infoUsuario = Object.assign(item);
      this.dialogAsignacionModulos = true;
    },

    //función para vinculación del dispositivo
    fnVinculacionDispositivo(item) {
      console.log('Vinculacíon de dispositivo', item);
      this.infoUsuario = Object.assign(item);
      this.dialogViculacionDispositivo = true;
    },
    //función para cuando se da clic en el botón de desvincular dispositivo
    fnDesvincularDispositivo() {
      this.patchUsuarios([this.infoUsuario.id, {
        dispositivo_id: null,
        dispositivo_marca: null,
        dispositivo_modelo: null,
        dispositivo_reg_token: null,
        //
        desvinculacion_usuario: this.currentUser.usuario,
        desvinculacion_usuario_id: this.currentUser.id,
        desvinculacion_motivo: this.desvinculacionMotivo,
        desvinculacion_fecha: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSSS+00:00"),

      }]).then(() => {
        this.$notify({
          title: 'Usuarios',
          text:
            'Se ha desvinculado el dispositivo para:' +
            this.infoUsuario.usuario,
          color: '#4caf50',
          timeout: 3000,
        });
      }).catch((e) => {
        console.log(e);
        
        this.$notify({
          title: 'Error',
          text: e,
          color: '#D32F2F',
          timeout: 3000,
        });
      });
      console.log('dispositivo desvinculado');
    },

    //función para ver el historial/registro de cambios
    fnVerRegistros(item) {
      console.log('main - mostrar historico de usuario: ', item);
      
      this.ver_log_de_usuario_id = item.id,
      this.dialogHistorico = true;
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
  },
};
</script>

<style></style>
