<template>
    <div>
        <v-data-table disable-pagination :options.sync="options" :hide-default-footer="true" :headers="headers"
            :items="datosTabla" item-key="name" class="elevation-2">
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ fnGetDate(item.createdAt) }}
            </template>

        </v-data-table>
        <!-- PAGINACION -->
        <v-col cols="12">
            <v-col offset="9" cols="3">
                <v-select v-model="pageSize" :items="pageSizes" label="Registros por página"
                    @change="handlePageSizeChange"></v-select>
            </v-col>
            <v-col class="">
                Total de registros: {{ totalRegistros }}
                <v-pagination class="float-right" v-model="page" :length="totalPages" total-visible="15"
                    next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="handlePageChange"></v-pagination>
            </v-col>
        </v-col>
        <!-- FIN PAGINACION -->

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
export default {
    props: ['prop_ver_log_de_usuario_id', 'prop_dialogHistorico'],
    data() {
        return {

            headers: [
                { text: 'ID:', value: 'id' },
                {
                    text: 'Modificado por',
                    value: 'EDITADO_POR_USUARIO_NOMBRE',
                },
                { text: 'Cambios', value: 'cambios' },

                { text: 'Fecha', value: 'createdAt' },
            ],
            //
            datosTabla: [],
            //pagination
            options: {},
            page: 1,
            totalPages: 0,
            pageSize: 10,
            pageSizes: [10, 25, 50, 100],
            totalRegistros: 0,
            //pagination fin
        };
    },
    methods: {
        fnGetDate(date) {
            return date ? moment(date).format('DD-MM-YYYY HH:mm:ss a') : '';
        },
        ...mapActions('UsuariosCambiosHistorico', {
            findUsuariosCambiosHistorico: 'find',
            getUsuariosCambiosHistorico: 'get',
            createUsuariosCambiosHistorico: 'create',
            patchUsuariosCambiosHistorico: 'patch',
        }),
        /**paginacion */
        handlePageChange(value) {
            this.page = value;
            this.main();
        },
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.page = 1;
            this.main();
        },
        /**paginacion fin */
        getParams() {
            const params = {};
            /**paginacion */
            params['$limit'] = this.pageSize;
            params['$skip'] = (this.page - 1) * this.pageSize;
            params['usuario_id'] = this.prop_ver_log_de_usuario_id;
            params['$sort'] = { id: -1 };
            /**paginacion fin */

            return params;
        },
        async main() {
            await this.findUsuariosCambiosHistorico({ query: this.getParams() }).then(
                (r) => {
                    console.log('historico encontreado: ', r);

                    this.datosTabla = r.data;
                    this.totalRegistros = r.total;
                    this.totalPages = Math.ceil(
                        parseInt(r.total) / parseInt(this.pageSize)
                    );
                }
            ).catch(e => {
                console.log('error para encontrar historico', e);

            });
        },
    },
    watch: {
        prop_dialogHistorico: {
            // eslint-disable-next-line no-unused-vars
            handler(newValue) {
                console.log('prop_dialogHistorico', newValue);
                if (newValue == true) {
                    this.tbl_loading = true;
                    this.main();    
                }
                
            },
            deep: true,
            immediate: true,
        },
        prop_ver_log_de_usuario_id: {
            // eslint-disable-next-line no-unused-vars
            handler(newValue) {
                console.log('prop_ver_log_de_usuario_id', newValue);

                this.tbl_loading = true;
                this.main();
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>