<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        Crear usuario
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">{{ prop_tipo }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field v-model="record.usuario" label="Usuario" :rules="[rules.required]" required></v-text-field>

          <v-text-field v-model="record.nombre" label="Nombre" :rules="[rules.required]" required></v-text-field>

          <v-text-field v-model="record.telefono" label="Número de teléfono" :rules="[rules.required, rules.phone]"
            required type="tel" maxlength="8"></v-text-field>

          <v-text-field ref="password" v-model="record.password" label="Contraseña" :rules="[rules.required]" required
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"></v-text-field>
          <v-text-field v-model="record.passValid" label="Verificar contraseña" :rules="[rules.required]" required
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"></v-text-field>

          <!-- <v-autocomplete
            ref="rolSelect"
            v-model="lcl_rol"
            :items="roles"
            :loading="loadingRoles"
            :search-input.sync="searchRolesInput"
            label="Rol"
            item-text="nombre"
            item-value="id"
            @update:search-input="searchRoles"
            required
            return-object
          ></v-autocomplete> -->

          <v-autocomplete disabled v-model="lcl_pais" :items="paises" :loading="loadingPaises"
            :search-input.sync="searchPaisesInput" label="País" item-text="nombre" item-value="id"
            @update:search-input="searchPaises" required return-object></v-autocomplete>

          <v-autocomplete disabled v-model="lcl_division" :items="divisiones" :loading="loadingDivisiones"
            :search-input.sync="searchDivisionesInput" label="División" item-text="nombre" item-value="id"
            @update:search-input="searchDivisiones" required return-object></v-autocomplete>

          <v-autocomplete disabled v-model="lcl_region" :items="regiones" :loading="loadingRegiones"
            :search-input.sync="searchRegionesInput" label="Región" item-text="nombre" item-value="id"
            @update:search-input="searchRegiones" required return-object></v-autocomplete>

          <v-autocomplete v-model="lcl_sector" :items="sectores" :loading="loadingSectores"
            :search-input.sync="searchSectoresInput" label="Sector" item-text="nombre" item-value="id"
            @update:search-input="searchSectores" required return-object></v-autocomplete>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="fnCrearUsuario">Crear</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');

export default {
  props: {
    prop_tipo: {
      type: String,
      default: 'Agente de comercio',
    },
  },
  data() {
    return {
      show1: false, // para mostrar o no la contraseña en crear usuario
      show2: false, // para el campo de validación de contraseña
      dialog: false,
      valid: false,
      //
      lcl_rol: {
        id: 2,
        nombre: 'Agente de comercio'
      },
      lcl_pais: null,
      lcl_division: null,
      lcl_region: null,
      lcl_sector: null,
      //
      record: {
        usuario: '',
        nombre: '',
        password: '',
        passValid: '',
        telefono: '',
        rol_id: 2,
        rol_nombre: 'Agente de comercio',
        CREADA_POR_USUARIO_NOMBRE: null,
        CREADA_POR_USUARIO_ID: null,
        id_pais: null,
        pais: null,
        id_division: null,
        division: null,
        id_region: null,
        region: null,
        id_sector: null,
        sector: null,
      },
      roles: [],
      searchRolesInput: '',
      loadingRoles: false,
      paises: [],
      searchPaisesInput: '',
      loadingPaises: false,
      divisiones: [],
      searchDivisionesInput: '',
      loadingDivisiones: false,
      regiones: [],
      searchRegionesInput: '',
      loadingRegiones: false,
      sectores: [],
      searchSectoresInput: '',
      loadingSectores: false,
      rules: {
        required: (value) => !!value || 'Required.',
        phone: value => /^\d{8}$/.test(value) || "El número debe tener exactamente 8 dígitos."
      },
    };
  },
  watch: {
    lcl_sector: function (newSector) {
      console.log('Sector changed:', newSector);
      if (newSector) {
        // A new sector was selected
        console.log('New sector selected:', newSector);
        // You can update other fields or perform actions here
        this.lcl_pais =
          this.paises.find((d) => d.id === newSector.pais_id) || null;
        this.lcl_division =
          this.divisiones.find((d) => d.id === newSector.division_id) || null;
        this.lcl_region =
          this.regiones.find((r) => r.id === newSector.region_id) || null;
      } else {
        // The sector was cleared
        console.log('Sector cleared');
        // Reset related fields if needed
        this.lcl_pais = null;
        this.lcl_division = null;
        this.lcl_region = null;
      }
    },
    searchRolesInput(val) {
      this.searchRoles(val);
    },
    searchPaisesInput(val) {
      this.searchPaises(val);
    },
    searchDivisionesInput(val) {
      this.searchDivisiones(val);
    },
    searchRegionesInput(val) {
      this.searchRegiones(val);
    },
    searchSectoresInput(val) {
      this.searchSectores(val);
    },
  },
  methods: {
    ...mapActions('Usuarios', {
      findUsuarios: 'find',
      getUsuarios: 'get',
      createUsuarios: 'create',
      patchUsuarios: 'patch',
    }),
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },
    close() {
      this.dialog = false;
    },
    async fnCrearUsuario() {
      //verificamos si las contraseñas ingresadas coinciden
      if (this.record.password != this.record.passValid) {
        this.$refs.password.focus();
        this.$notify({
          title: 'Error',
          text: 'Las contraseñas no coinciden',
          color: '#D32F2F',
          timeout: 3000,
        });

        return false;
      }

      //verificamos si se selecciono un rol
      // if (!this.lcl_rol) {
      //   this.$refs.rolSelect.focus();

      //   this.$notify({
      //     title: 'Error',
      //     text: 'Este campo es obligatorio "rol"',
      //     color: '#D32F2F',
      //     timeout: 3000,
      //   });
      //   return false;
      // }

      if (this.$refs.form.validate()) {
        // Your save logic, e.g., API call

        this.record.rol_id = this.lcl_rol.id;
        this.record.rol_nombre = this.lcl_rol.nombre;
        //
        this.record.id_pais = this.lcl_pais.id;
        this.record.pais = this.lcl_pais.nombre;
        //

        this.record.id_division = this.lcl_division.id;
        this.record.division = this.lcl_division.nombre;
        //
        this.record.id_region = this.lcl_region.id;
        this.record.region = this.lcl_region.nombre;
        //
        this.record.id_sector = this.lcl_sector.id;
        this.record.sector = this.lcl_sector.nombre;
        //
        this.record.CREADA_POR_USUARIO_NOMBRE = this.currentUser.nombre;
        this.record.CREADA_POR_USUARIO_ID = this.currentUser.id;
        console.log('sending record: ', this.record);
        await this.createUsuarios(this.record)
          .then(() => {
            this.$notify({
              title: 'Usuarios',
              text:
                'Se ha creado el usuario agente de comercio ' +
                this.record.usuario,
              color: '#4caf50',
              timeout: 3000,
            });
            this.dialog = false;
            this.$refs.form.reset();
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#D32F2F',
              timeout: 3000,
            });

          });
      }
    },
    searchRoles(val) {
      const params = {};
      if (val) {
        params['nombre'] = {
          $like: '%' + val + '%',
          $limit: 100,
        };
      }

      this.loadingRoles = true;
      axios
        .get(process.env.VUE_APP_FEATHERS_SERVER + 'roles', {
          params: params,
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + window.localStorage.getItem('feathers-jwt'),
          },
        })
        .then((response) => {
          console.log('axios roles: ', response);
          this.roles = response.data.data;
          this.loadingRoles = false;
        })
        .catch(() => {
          this.loadingRoles = false;
        });
    },
    searchPaises(val) {
      const params = {};
      if (val) {
        params['nombre'] = {
          $like: '%' + val + '%',
          $limit: 100,
        };
      }

      this.loadingPaises = true;
      axios
        .get(process.env.VUE_APP_FEATHERS_SERVER + 'paises', {
          params: params,
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + window.localStorage.getItem('feathers-jwt'),
          },
        })
        .then((response) => {
          console.log('axios paises: ', response);
          this.paises = response.data.data;
          this.loadingPaises = false;
        })
        .catch(() => {
          this.loadingPaises = false;
        });
    },
    searchDivisiones(val) {
      const params = {};
      if (val) {
        params['nombre'] = {
          $like: '%' + val + '%',
          $limit: 100,
        };
      }

      this.loadingDivisiones = true;
      axios
        .get(process.env.VUE_APP_FEATHERS_SERVER + 'divisiones', {
          params: params,
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + window.localStorage.getItem('feathers-jwt'),
          },
        })
        .then((response) => {
          console.log('axios divisiones: ', response);
          this.divisiones = response.data.data;
          this.loadingDivisiones = false;
        })
        .catch(() => {
          this.loadingDivisiones = false;
        });
    },
    searchRegiones(val) {
      const params = {};
      if (val) {
        params['nombre'] = {
          $like: '%' + val + '%',
        };
      }
      params['$limit'] = 100;

      this.loadingRegiones = true;
      axios
        .get(process.env.VUE_APP_FEATHERS_SERVER + 'regiones', {
          params: params,
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + window.localStorage.getItem('feathers-jwt'),
          },
        })
        .then((response) => {
          console.log('axios regiones: ', response);
          this.regiones = response.data.data;
          this.loadingRegiones = false;
        })
        .catch(() => {
          this.loadingRegiones = false;
        });
    },
    searchSectores(val) {
      const params = {};
      if (val) {
        params['nombre'] = {
          $like: '%' + val + '%',
          $limit: 100,
        };
      }

      this.loadingSectores = true;
      axios
        .get(process.env.VUE_APP_FEATHERS_SERVER + 'sectores', {
          params: params,
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + window.localStorage.getItem('feathers-jwt'),
          },
        })
        .then((response) => {
          console.log('axios sectores: ', response);
          this.sectores = response.data.data;
          this.loadingSectores = false;
        })
        .catch(() => {
          this.loadingSectores = false;
        });
    },
  },
  mounted() {
    this.searchRoles();
    this.searchPaises();
    this.searchDivisiones();
    this.searchRegiones();
    this.searchSectores();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
  },
};
</script>