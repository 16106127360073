<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small text color="primary" dark v-bind="attrs" v-on="on">
        <v-icon
          small
          title="editar agente de comercio"
          class="align-center mr-2"
          color="black"
        >
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">{{ prop_tipo }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="record.usuario"
            label="Usuario"
            :rules="[rules.required]"
            required
          ></v-text-field>

          <v-text-field
            v-model="record.nombre"
            label="Nombre"
            :rules="[rules.required]"
            required
          ></v-text-field>


          <v-text-field
            v-model="record.telefono"
            label="Número de teléfono"
            :rules="[rules.required, rules.phone]"
            required
          ></v-text-field>

          <v-text-field
            ref="password"
            v-model="passEdit"
            label="Contraseña"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
          ></v-text-field>
          <v-text-field
            v-model="passValidEdit"
            label="Verificar contraseña"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
          ></v-text-field>

          <v-autocomplete
            disabled
            ref="rolSelect"
            v-model="lcl_rol"
            :items="roles"
            :loading="loadingRoles"
            :search-input.sync="searchRolesInput"
            label="Rol"
            item-text="nombre"
            item-value="id"
            required
            return-object
          ></v-autocomplete>

          <v-autocomplete
            disabled
            v-model="lcl_pais"
            :items="paises"
            :loading="loadingPaises"
            :search-input.sync="searchPaisesInput"
            label="País"
            item-text="nombre"
            item-value="id"
            required
            return-object
          ></v-autocomplete>

          <v-autocomplete
            disabled
            v-model="lcl_division"
            :items="divisiones"
            :loading="loadingDivisiones"
            :search-input.sync="searchDivisionesInput"
            label="División"
            item-text="nombre"
            item-value="id"
            required
            return-object
          ></v-autocomplete>

          <v-autocomplete
            disabled
            v-model="lcl_region"
            :items="regiones"
            :loading="loadingRegiones"
            :search-input.sync="searchRegionesInput"
            label="Región"
            item-text="nombre"
            item-value="id"
            required
            return-object
          ></v-autocomplete>

          <v-autocomplete
            v-model="lcl_sector"
            :items="sectores"
            :loading="loadingSectores"
            :search-input.sync="searchSectoresInput"
            label="Sector"
            item-text="nombre"
            item-value="id"
            @update:search-input="searchSectores"
            required
            return-object
          ></v-autocomplete>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="fnEditarUsuario"
          >Editar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');

export default {
  props: ['prop_existing_user', 'prop_tipo'],
  data() {
    return {
      passEdit: null,
      passValidEdit: null,
      /** */
      show1: false, // para mostrar o no la contraseña en editar usuario
      show2: false, // para el campo de validación de contraseña
      dialog: false,
      valid: false,
      //
      lcl_rol: null,
      lcl_pais: null,
      lcl_division: null,
      lcl_region: null,
      lcl_sector: null,
      //
      record: {
        usuario: '',
        nombre: '',
        password: '',
        telefono: '',
        passValid: '',
        rol_id: null,
        rol_nombre: '',
        CREADA_POR_USUARIO_NOMBRE: null,
        CREADA_POR_USUARIO_ID: null,
        id_pais: null,
        pais: null,
        id_division: null,
        division: null,
        id_region: null,
        region: null,
        id_sector: null,
        sector: null,
      },
      roles: [],
      searchRolesInput: '',
      loadingRoles: false,
      paises: [],
      searchPaisesInput: '',
      loadingPaises: false,
      divisiones: [],
      searchDivisionesInput: '',
      loadingDivisiones: false,
      regiones: [],
      searchRegionesInput: '',
      loadingRegiones: false,
      sectores: [],
      searchSectoresInput: '',
      loadingSectores: false,
      rules: {
        required: (value) => !!value || 'Required.',
        phone: value => /^\d{8}$/.test(value) || "El número debe tener exactamente 8 dígitos."
      },
    };
  },

  watch: {
    prop_existing_user: {
      handler(newValue) {
        console.log('prop_existing_user', newValue);
        let vm = this;
        setTimeout(() => {
          vm.record.usuario = newValue.usuario;
          vm.record.nombre = newValue.nombre;
          vm.record.telefono = newValue.telefono;
          vm.lcl_rol = vm.roles.find((rol) => rol.id == newValue.rol_id);
          // vm.lcl_pais = vm.paises.find((pais) => pais.id == newValue.id_pais);
          // vm.lcL_division = vm.divisiones.find((division) => division.id == newValue.id_division);
          // vm.lcl_region = vm.regiones.find((region) => region.id == newValue.id_region);
          vm.lcl_sector = vm.sectores.find(
            (sector) => sector.id == newValue.id_sector
          );
        }, 300);
      },
      deep: true,
      immediate: true,
    },
    lcl_sector: function (newSector) {
      console.log('Sector changed:', newSector);
      if (newSector) {
        // A new sector was selected
        console.log('New sector selected:', newSector);
        // You can update other fields or perform actions here
        this.lcl_pais =
          this.paises.find((d) => d.id == newSector.pais_id) || null;
        this.lcl_division =
          this.divisiones.find((d) => d.id == newSector.division_id) || null;
        this.lcl_region =
          this.regiones.find((r) => r.id == newSector.region_id) || null;
      } else {
        // The sector was cleared
        console.log('Sector cleared');
        // Reset related fields if needed
        this.lcl_pais = null;
        this.lcl_division = null;
        this.lcl_region = null;
      }
    },
  },
  methods: {
    ...mapActions('Usuarios', {
      findUsuarios: 'find',
      getUsuarios: 'get',
      createUsuarios: 'create',
      patchUsuarios: 'patch',
    }),
    ...mapActions('UsuariosCambiosHistorico', {
      findUsuariosCambiosHistorico: 'find',
      getUsuariosCambiosHistorico: 'get',
      createUsuariosCambiosHistorico: 'create',
      patchUsuariosCambiosHistorico: 'patch',
    }),
    //
    compareObjects(obj_original, obj_bodyRequest, ignoreKeys = []) {
      const changes = [];

      for (const key in obj_bodyRequest) {
        if (ignoreKeys.includes(key)) {
          continue; // Ignorar esta clave si está en la lista de ignoreKeys
        }

        if (Object.prototype.hasOwnProperty.call(obj_original, key)) {
          const oldValue = obj_original[key];
          let nuevoValor = obj_bodyRequest[key];

          if (oldValue !== nuevoValor) {
            changes.push(
              `El valor de '${key}' cambió de '${oldValue}' a '${nuevoValor}'.`
            );
          }
        } else {
          if (key == 'password') {
            changes.push(`El valor de '${key}' cambió.`);
          } else {
            changes.push(
              `Se agregó el campo '${key}' con valor '${obj_bodyRequest[key]}'.`
            );
          }
        }
      }

      return changes.length > 0
        ? changes.join('\n')
        : 'No se detectaron cambios.';
    },
    //
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },
    close() {
      this.dialog = false;
    },
    fnEditarUsuario() {
      let version = null;
      if (this.prop_existing_user.VERSION === null) {
        version = 1;
      } else {
        console.log('aumentando version');
        version = this.prop_existing_user.VERSION + 1;
      }

      let REQUEST_BODY_TO_SEND = {
        // Your save logic, e.g., API call
        nombre: this.record.nombre,
        usuario: this.record.usuario,
        telefono: this.record.telefono,
        //
        id_pais: this.lcl_pais.id,
        pais: this.lcl_pais.nombre,
        //
        id_division: this.lcl_division.id,
        division: this.lcl_division.nombre,
        //
        id_region: this.lcl_region.id,
        region: this.lcl_region.nombre,
        //
        id_sector: this.lcl_sector.id,
        sector: this.lcl_sector.nombre,

        VERSION: version,
        EDITADO_POR_USUARIO_ID: this.currentUser.id,
        EDITADO_POR_USUARIO_NOMBRE: this.currentUser.usuario,
        EDITADO_FECHA: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      };

      //verificamos si las contraseñas ingresadas coinciden
      if (this.passEdit) {
        if (this.passEdit === this.passValidEdit) {
          REQUEST_BODY_TO_SEND = Object.assign(REQUEST_BODY_TO_SEND, {
            password: this.passEdit,
          });
        } else {
          //si las contraseñas no son iguales las reiniciamos y se muestra en rojo para que las vuelvan a ingresar

          this.$notify({
            title: 'Error',
            text: 'Las contraseñas no coinciden, por favor intentalo de nuevo.',
            color: '#D32F2F',
            timeout: 3000,
          });

          return false;
        }
      }

      if (this.$refs.form.validate()) {
        console.log('sending record: ', this.prop_existing_user);
        const detectar_cambios = this.compareObjects(
          this.prop_existing_user,
          REQUEST_BODY_TO_SEND,
          ['VERSION', 'EDITADO_FECHA']
        );
        if (detectar_cambios) {
          this.createUsuariosCambiosHistorico({
            usuario_id: this.prop_existing_user.id,
            usuario_nombre: this.prop_existing_user.nombre,
            usuario: this.prop_existing_user.usuario,
            cambios: detectar_cambios,
            EDITADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
            EDITADO_POR_USUARIO_ID: this.currentUser.id,
            EDITADO_FECHA: moment()
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
          })
            .then(() => {
              console.log('historico creado');
            })
            .catch((e) => {
              console.log('error al crear  historico', e);
            });
        }

        this.createUsuariosCambiosHistorico;
        this.patchUsuarios([this.prop_existing_user.id, REQUEST_BODY_TO_SEND])
          .then(() => {
            this.$notify({
              title: 'Usuarios',
              text:
                'Se ha editado el usuario agente de comercio ' +
                this.prop_existing_user.usuario,
              color: '#4caf50',
              timeout: 3000,
            });
            this.dialog = false;
            this.$refs.form.reset();
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#D32F2F',
              timeout: 3000,
            });
          });
      }
    },
    searchRoles(val) {
      const params = {};
      if (val) {
        params['nombre'] = {
          $like: '%' + val + '%',
        };
      }
      params['$limit'] = 500;
      this.loadingRoles = true;
      axios
        .get(process.env.VUE_APP_FEATHERS_SERVER + 'roles', {
          params: params,
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + window.localStorage.getItem('feathers-jwt'),
          },
        })
        .then((response) => {
          console.log('axios roles: ', response);
          this.roles = response.data.data;
          this.loadingRoles = false;
        })
        .catch(() => {
          this.loadingRoles = false;
        });
    },
    searchPaises(val) {
      const params = {};
      if (val) {
        params['nombre'] = {
          $like: '%' + val + '%',
        };
      }
      params['$limit'] = 500;
      this.loadingPaises = true;
      axios
        .get(process.env.VUE_APP_FEATHERS_SERVER + 'paises', {
          params: params,
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + window.localStorage.getItem('feathers-jwt'),
          },
        })
        .then((response) => {
          console.log('axios paises: ', response);
          this.paises = response.data.data;
          this.loadingPaises = false;
        })
        .catch(() => {
          this.loadingPaises = false;
        });
    },
    searchDivisiones(val) {
      const params = {};
      if (val) {
        params['nombre'] = {
          $like: '%' + val + '%',
        };
      }
      params['$limit'] = 500;
      this.loadingDivisiones = true;
      axios
        .get(process.env.VUE_APP_FEATHERS_SERVER + 'divisiones', {
          params: params,
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + window.localStorage.getItem('feathers-jwt'),
          },
        })
        .then((response) => {
          console.log('axios divisiones: ', response);
          this.divisiones = response.data.data;
          this.loadingDivisiones = false;
        })
        .catch(() => {
          this.loadingDivisiones = false;
        });
    },
    searchRegiones(val) {
      const params = {};
      if (val) {
        params['nombre'] = {
          $like: '%' + val + '%',
        };
      }
      params['$limit'] = 500;
      this.loadingRegiones = true;
      axios
        .get(process.env.VUE_APP_FEATHERS_SERVER + 'regiones', {
          params: params,
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + window.localStorage.getItem('feathers-jwt'),
          },
        })
        .then((response) => {
          console.log('axios regiones: ', response);
          this.regiones = response.data.data;
          this.loadingRegiones = false;
        })
        .catch(() => {
          this.loadingRegiones = false;
        });
    },
    searchSectores(val) {
      const params = {};
      if (val) {
        params['nombre'] = {
          $like: '%' + val + '%',
        };
      }
      params['$limit'] = 500;
      this.loadingSectores = true;
      axios
        .get(process.env.VUE_APP_FEATHERS_SERVER + 'sectores', {
          params: params,
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + window.localStorage.getItem('feathers-jwt'),
          },
        })
        .then((response) => {
          console.log('axios sectores: ', response);
          this.sectores = response.data.data;
          this.loadingSectores = false;
        })
        .catch(() => {
          this.loadingSectores = false;
        });
    },
  },
  mounted() {
    this.searchRoles();
    this.searchPaises();
    this.searchDivisiones();
    this.searchRegiones();
    this.searchSectores();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
  },
};
</script>